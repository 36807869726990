var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-app',[_c('div',{attrs:{"id":"center-management"}},[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"md":"12"}},[_c('div',{staticClass:"pa-10 pt-5"},[_c('div',{staticClass:"d-flex justify-space-between"},[_c('h2',{staticClass:"d-flex"},[_c('v-icon',{on:{"click":function($event){return _vm.back()}}},[_vm._v("arrow_back_ios")]),_c('div',{staticClass:"d-flex align-center"},[_vm._v("Request a leave of absence")])],1)]),_c('div',{staticClass:"success"},[_c('v-alert',{staticStyle:{"font-size":"14px","padding":"12px"},attrs:{"value":_vm.checkSuccess,"transition":"scale-transition","type":"success"}},[_vm._v(" Update absence successfully ")])],1),_c('div',{staticClass:"pt-5"},[_c('v-textarea',{attrs:{"outlined":"","label":"Reason for leave of absence","value":""},model:{value:(_vm.reason),callback:function ($$v) {_vm.reason=$$v},expression:"reason"}})],1),_c('div',{staticClass:"d-flex"},[_c('v-menu',{attrs:{"transition":"scale-transition","offset-y":"","close-on-content-click":false},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({staticClass:"mr-2",attrs:{"outlined":"","label":"From date","hide-details":""},model:{value:(_vm.fromDate),callback:function ($$v) {_vm.fromDate=$$v},expression:"fromDate"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.dropdownOpenFrom),callback:function ($$v) {_vm.dropdownOpenFrom=$$v},expression:"dropdownOpenFrom"}},[_c('div',{staticClass:"d-flex"},[_c('v-date-picker',{attrs:{"width":"290","no-title":false},model:{value:(_vm.dateFrom),callback:function ($$v) {_vm.dateFrom=$$v},expression:"dateFrom"}}),_c('v-time-picker',{attrs:{"format":"24hr","color":"primary","width":"290","no-title":false},model:{value:(_vm.timeFrom),callback:function ($$v) {_vm.timeFrom=$$v},expression:"timeFrom"}},[_c('div',{staticClass:"d-flex justify-end pt-5 pb-5",staticStyle:{"margin-left":"35%"}},[_c('v-btn',{staticClass:"mr-3",attrs:{"outlined":"","elevation":"0","color":"error"},on:{"click":function($event){_vm.dropdownOpenFrom = false}}},[_vm._v("Cancel")]),_c('v-btn',{staticClass:"mr-3",attrs:{"color":"primary","elevation":"0"},on:{"click":function($event){return _vm.confirmFrom()}}},[_vm._v("Confirm")])],1)])],1)]),_c('v-menu',{attrs:{"transition":"scale-transition","offset-y":"","close-on-content-click":false},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({staticClass:"ml-2",attrs:{"outlined":"","label":"To Date","hide-details":""},model:{value:(_vm.untilDate),callback:function ($$v) {_vm.untilDate=$$v},expression:"untilDate"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.dropdownOpenUntil),callback:function ($$v) {_vm.dropdownOpenUntil=$$v},expression:"dropdownOpenUntil"}},[_c('div',[_c('div',{staticClass:"d-flex"},[_c('v-date-picker',{attrs:{"width":"290","no-title":false},model:{value:(_vm.dateUntil),callback:function ($$v) {_vm.dateUntil=$$v},expression:"dateUntil"}}),_c('v-time-picker',{attrs:{"format":"24hr","color":"primary","width":"290","no-title":false},model:{value:(_vm.timeUntil),callback:function ($$v) {_vm.timeUntil=$$v},expression:"timeUntil"}},[_c('div',{staticClass:"d-flex justify-end pt-5 pb-5",staticStyle:{"margin-left":"35%"}},[_c('v-btn',{staticClass:"mr-3",attrs:{"outlined":"","color":"error","elevation":"0"},on:{"click":function($event){_vm.dropdownOpenUntil = false}}},[_vm._v("Cancel")]),_c('v-btn',{staticClass:"mr-3",attrs:{"color":"primary","elevation":"0"},on:{"click":function($event){return _vm.confirmUntil()}}},[_vm._v("Confirm")])],1)])],1)])]),_c('v-icon',{attrs:{"color":"primary","x-large":"","disabled":_vm.fromDate && _vm.untilDate ? false : true},on:{"click":function($event){_vm.showView = true}}},[_vm._v("mdi-alert-circle")]),_c('v-btn',{staticClass:"mr-3 mt-2",attrs:{"color":"primary","disabled":_vm.reason &&
                _vm.isSameOrAfter(_vm.fromDateTimeFormat, _vm.today) &&
                _vm.isSameOrAfter(_vm.untilDateTimeFormat, _vm.today) &&
                _vm.isSameOrAfter(_vm.untilDateTimeFormat, _vm.fromDateTimeFormat)
                  ? false
                  : true},on:{"click":function($event){return _vm.request()}}},[_vm._v("Request")])],1),(_vm.showView === true)?_c('div',[_c('h3',{staticClass:"pt-5 pb-5"},[_vm._v(" View my schedule "),(_vm.fromDate)?_c('span',[_vm._v("from "+_vm._s(_vm.fromDate))]):_vm._e(),(_vm.untilDate)?_c('span',[_vm._v(" to "+_vm._s(_vm.untilDate))]):_vm._e()]),_c('v-data-table',{staticClass:"elevation-0",attrs:{"headers":_vm.headers,"items":_vm.lisAbsenceDetail,"page":_vm.page,"items-per-page":_vm.itemsPerPage,"hide-default-footer":"","height":"60vh","fixed-header":""},on:{"update:page":function($event){_vm.page=$event},"page-count":function($event){_vm.pageCount = $event}},scopedSlots:_vm._u([{key:"item.no",fn:function(ref){
                  var index = ref.index;
return [_vm._v(" "+_vm._s(index + 1)+" ")]}},{key:"item.date",fn:function(ref){
                  var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._dayjs(item.date).format('YYYY-MM-DD'))+" ")]}},{key:"item.status",fn:function(ref){return [_c('v-chip',{attrs:{"text-color":"blue","small":""}},[_vm._v("pending")])]}},{key:"item.action",fn:function(ref){
                  var item = ref.item;
return [_c('div',{staticClass:"d-flex"},[_c('i',{staticClass:"isax isax-edit-2 fs-18 font-weight-bold mr-4",on:{"click":function($event){return _vm.editClass(item.classID)}}}),_c('v-icon',{attrs:{"size":"18","color":"primary"}},[_vm._v("visibility")])],1)]}}],null,true)})],1):_vm._e()])])],1),_c('v-footer',{staticClass:"pl-10 pr-10",attrs:{"height":"60","color":"#fff","width":"95%"}},[_c('v-row',[(_vm.showView === true)?_c('v-col',{attrs:{"md":"2"}},[_c('v-select',{staticStyle:{"height":"32px"},attrs:{"outlined":"","hide-details":"","dense":"","value":_vm.itemsPerPage,"items":[20, 50, 100, 200]},on:{"change":function($event){_vm.itemsPerPage = parseInt($event, 10)}}})],1):_vm._e(),(_vm.showView === true)?_c('v-col',{staticClass:"d-flex justify-end",attrs:{"md":"10"}},[_c('v-pagination',{attrs:{"length":_vm.pageCount,"elevation":"0"},model:{value:(_vm.page),callback:function ($$v) {_vm.page=$$v},expression:"page"}})],1):_vm._e(),_c('v-col',{staticClass:"d-flex justify-end"})],1)],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }